import React, {useEffect, useState} from "react";
import DataTable, {createTheme} from "react-data-table-component";
import CustomPlaceholder from "../../../components/Placeholder/CustomPlaceHolder.js";
import {CSVLink} from 'react-csv';
import axios from "axios";

const formatTime = (milliseconds) => {
    if (milliseconds === 'undefined') {
        return `0h 0m`
    }
    const seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
  
    const remainingMinutes = minutes % 60;
    const remainingSeconds = seconds % 60;
  
    return `${hours}h ${remainingMinutes}m`;
};

const checkLast = (lastCon) => {
    if (lastCon === 'undefined') {
        return 'nunca'
    }
    return lastCon
};

const ReportByUserTable = () => {
    ////////////////////////////
    // GET USERS DATA
    ////////////////////////////
    const [loading, setLoading] = useState(true);
    const [reportData, setReportData] = useState([]);

    const getReport = async () => {
        try {
            const endpoint = `${process.env.REACT_APP_ROOT_URL}${process.env.REACT_APP_REPORT_BY_USER}`
            const response = await axios.get(endpoint)
            const data = await response.data;
            setReportData(data);
        } catch (error) {
            console.error("Error fetching report data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getReport();
        setLoading(false);
    }, []);

    useEffect(() => {
        setLoading(false);
    }, []);

    const ExportCSV = ({ data }) => {
        const headers = [
          { label: 'Nombre', key: 'name' },
          { label: 'Email', key: 'email' },
          { label: 'Cursos', key: `details` },
        ];

        return (
          <CSVLink data={reportData} headers={headers} filename={"Métricas por Usuario.csv"}>
            Descargar CSV
          </CSVLink>
        );
    };

    ////////////////////////////
    // SET DATABLES COLUMNS
    ////////////////////////////

    const columns = [
        {
            name: "Nombre",
            selector: (row) => row.name,
            sortable: true,
            reorder: true,
        },
        {
            name: "Email",
            selector: (row) => row.email,
            sortable: true,
            reorder: true,
        },
        {
            name: "Cursos",
            selector: (row) => row.courses.length,
            sortable: true,
            reorder: true,
        },
        {
            name: "Ultima Conexión",
            selector: (row) => checkLast(row.lastConnection),
            sortable: true,
            reorder: true,
        },
        {
            name: "Tiempo Total de Conexión",
            selector: (row) => formatTime(row.totalConnectionTime),
            sortable: true,
            reorder: true,
        },

    ];


    ////////////////////////////
    // SET DATABLES THEME
    ////////////////////////////
    createTheme(
        "tradeTheme",
        {
            text: {
                primary: "#333333",
                secondary: "#5517DC",
            },
            background: {
                default: "#f0f0f0",
            },
            context: {
                background: "#ffae42",
                text: "#000000",
            },
            divider: {
                default: "#cccccc",
            },
            action: {
                button: "#5517DC",
                hover: "#ff8c66",
                disabled: "#999999",
            },
        },
        "light"
    );

    const paginationComponentOptions = {
        rowsPerPageText: "Filas por página",
        rangeSeparatorText: "de",
        selectAllRowsItem: true,
        selectAllRowsItemText: "Todos",
    };

    const ExpandedComponent = ({data}) => (
        <div className="p-4">
            <table>
                <tr>
                    <td><b>Curso</b></td>
                    <td><b>Progreso</b></td>
                    <td className=" mx-2"><b>Resultado</b></td>
                </tr>
                {data.courses.map(course => (
                    <tr>
                        <td className="p-2" key={course.name}>{course.name}</td>
                        <td className="p-2" style={{textAlign: "center"}} key={course.completed}>{course.completed}%</td>
                        <td className="p-2 mx-2" style={{textAlign: "center"}} key={course.totalCompletion}>{course.totalCompletion}%</td>
                    </tr>
                ))}
            </table>
        </div>
    );

    return (
        <div className="container-fluid table-container px-5 mt-3 mb-3">
            {loading ? (
                <CustomPlaceholder/>
            ) : (
                <div>
                <ExportCSV data={reportData} />
                <DataTable
                    columns={columns}
                    data={reportData}
                    noDataComponent="Cargando..."
                    responsive={true}
                    theme={"tradeTheme"}
                    style={{fontSize: "18px"}}
                    pagination
                    paginationComponentOptions={paginationComponentOptions}
                    expandableRows
                    expandableRowsComponent={ExpandedComponent}
                />
                </div>
            )}
        </div>
    );
};

export default ReportByUserTable;
