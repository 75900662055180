import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import useUserRoleLocalStorage from "../components/UseUserRoleLocalStorage/UseUserRoleLocalStorage";
import Courses from "../pages/course/list";
import Course from "../pages/course/details";
import Create from "../pages/course/create";
import ErrorNoAccess from "../components/NoAccess/NoAccess";
import AdminAllCourses from "../pages/administration/courses";
import AdminAllUsers from "../pages/administration/users";
import AdminAllRequest from "../pages/administration/requests";
import AdminAllActivationCodes from "../pages/administration/activationCodes";
import AdminAllCategories from "../pages/administration/categories";
import Home from "../pages/home";
import Login from "../pages/login";
import Modify from "../pages/course/modify";
import Register from "../pages/register";
import Error from "../pages/404";
import LoadTop from "../components/Common/ScrollTop/LoadTop";
import AdminReportUsers from "../pages/reports/users";
import AdminReportCourses from "../pages/reports/courses";
import MyAccount from "../pages/myAccount";
import ForgotPassword from "../pages/forgotPassword";
import PasswordReset from "../pages/passwordReset";
import CourseFlow from "../pages/course/flow/index";
import ManageEmails from "../pages/administration/emails";
import Groups from "../pages/groups/list";
import Campañas from "../pages/campaigns/index"
import Pagos from "../pages/pagos/index";
import Loader from "../pages/emailLoader/index"
import ReactGA from 'react-ga4';
import GA4_MEASUREMENT_ID from '../GA4Config'

const reportUserExit = async (email) => {
    const date = new Date();
    await axios.post(`${process.env.REACT_APP_ROOT_URL}/reports/reportUser`,{
        type: 'session_end',
        data: {
            date: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`, 
            time: `${date.getHours()}:${date.getMinutes()}`, 
            endTime: Date.now()
        },
        user: email}
    );
}

const reportUserEntry = async (email) => {
    const date = new Date();
    await axios.post(`${process.env.REACT_APP_ROOT_URL}/reports/reportUser`,{
        type: 'session_start', 
        data: {
            date: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`, 
            time: `${date.getHours()}:${date.getMinutes()}`, 
            startTime: Date.now()
        },
        user: email}
    );
}

const App = () => {
    const userRole = useUserRoleLocalStorage();
    const isAdmin = userRole !== 'user';

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: window.location.pathname
        });
    },[]);

    useEffect(() => {
        try{
            const token = localStorage.getItem('userProfile');
            const email = JSON.parse(token).token.email;
            window.addEventListener('beforeunload', (event) => {
                reportUserExit(email);
            });
    
            window.addEventListener('load', (event) => {
                reportUserEntry(email);
            });
        }
        catch (error) {
            console.error(error.message)
        }
    }, [window.location]);
    
    return (
        <div className="App">
            <Router>
                <LoadTop/>
                <Switch>
                    <Route path="/register" component={Register}/>
                    <Route path="/login" component={Login}/>
                    <Route path="/forgotPassword" component={ForgotPassword}/>
                    <Route path="/resetPassword/:token" component={PasswordReset}/>
                    <Route path="/" exact component={Home}/>
                    <Route path="/home" component={Home}/>
                    <Route path="/cursos" component={Courses}/>
                    <Route path="/course/course-single/:id" component={Course}/>
                    <Route path="/mis-cursos" component={MyAccount}/>
                    <Route path="/course-flow/:id" component={CourseFlow}/>
                    <Route path="/pagos" component={Pagos}/>


                    {/* Rutas de administración */}
                    {isAdmin && (
                        <>
                            <Route path="/admin/cursos" component={AdminAllCourses}/>
                            <Route path="/admin/usuarios" component={AdminAllUsers}/>
                            <Route path="/admin/request" component={AdminAllRequest}/>
                            <Route path="/admin/activationCodes" component={AdminAllActivationCodes}/>
                            <Route path="/admin/categories" component={AdminAllCategories}/>
                            <Route path="/admin/emails" component={ManageEmails}/>
                            {/*ruta campañas*/}
                            <Route path='/admin/campañas' component={Campañas}/>
                            <Route path="/groups" component={Groups}/>
                            <Route path="/report-users" component={AdminReportUsers}/>
                            <Route path="/report-courses" component={AdminReportCourses}/>
                            <Route path="/create" component={Create}/>
                            <Route path="/modify/:courseId" component={Modify}/>
                            <Route path="/admin/email-loader" component={Loader}/>
                            
                        </>
                    )}
                    {!isAdmin && (
                        <>
                            <Route path="/admin/cursos" component={ErrorNoAccess}/>
                            <Route path="/admin/usuarios" component={ErrorNoAccess}/>
                            <Route path="/admin/request" component={ErrorNoAccess}/>
                            <Route path="/admin/activationCodes" component={ErrorNoAccess}/>
                            <Route path="/admin/categories" component={ErrorNoAccess}/>
                            <Route path="/admin/emails" component={ErrorNoAccess}/>
                            <Route path='/admin/campañas' component={ErrorNoAccess}/>
                            <Route path="/admin/activationCategories" component={ErrorNoAccess}/>
                            <Route path="/groups" component={ErrorNoAccess}/>
                            <Route path="/report-users" component={ErrorNoAccess}/>
                            <Route path="/report-courses" component={ErrorNoAccess}/>
                            <Route path="/create" component={ErrorNoAccess}/>
                            <Route path="/modify/:courseId" component={ErrorNoAccess}/>
                            <Route path="/admin/email-loader" component={ErrorNoAccess}/>
                        </>
                    )}

                    {/* Ruta predeterminada */}
                    <Route path="/404" component={Error}/>

                    {/* Redireccionar a la página de error para cualquier otra ruta desconocida */}
                    <Redirect to="/404" component={Error}/>
                </Switch>
            </Router>
        </div>
    );
};

export default App;
